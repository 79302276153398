import React, { useState } from 'react';

export type BookingSearchForm2Props = {
  onSearch: (data: BookingSearchFormState) => void;
};

export type BookingSearchFormState = {
  dateFrom?: string;
  dateTo?: string;
  selectedStatuses?: string[];
  client?: string;
  cleaner?: string;
  region?: string;
  sortSelected?: "date" | "rdate" | 'name' | 'rname';
};

export default function BookingSearchForm2({ onSearch }: BookingSearchForm2Props) {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const statuses = ['requested', 'confirmed', 'accepted', 'rejected', 'paid', 'cancelled'] as const;
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([...statuses]);
  const [client, setClient] = useState('');
  const [cleaner, setCleaner] = useState('');
  const [region, setRegion] = useState('');
  const [sort, setSort] = useState<"date" | "rdate" | 'name' | 'rname'>("rdate");

  const sortOptions = [
    { label: "Date", id: "date" },
    { label: "Reverse Date", id: "rdate" },
    { label: "Name", id: "name" },
    { label: "Reverse Name", id: "rname" }
  ] as const;

  const handleDatePreset = (preset: 'today' | 'tomorrow' | 'next7days') => {
    const today = new Date();
    let fromDate = new Date();
    let toDate = new Date();

    switch (preset) {
      case 'today':
        break
      case 'tomorrow':
        fromDate.setDate(today.getDate() + 1);
        toDate = fromDate;
        break;
      case 'next7days':
        toDate.setDate(today.getDate() + 7);
        break;
    }

    setDateFrom(fromDate.toISOString().split('T')[0])
    setDateTo(toDate.toISOString().split('T')[0])
  }

  const handleStatusChange = (status: string) => {
    setSelectedStatuses(prev =>
      prev.includes(status)
        ? prev.filter(s => s !== status)
        : [...prev, status]
    )
  }

  const handleSearch = () => {
    onSearch({
      dateFrom,
      dateTo,
      selectedStatuses,
      client,
      cleaner,
      region,
      sortSelected: sort
    });
  }

  const regions = ['Calgary', 'Edmonton', 'Vancouver']

  return (
    <div className="max-w-full mx-auto px-8 py-6 my-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-8 text-lime-600">Search Form</h1>
      <div className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="space-y-2">
            <label htmlFor="client" className="block text-sm font-medium text-gray-700">Client</label>
            <input
              id="client"
              name="client"
              type="text"
              placeholder="Enter client name"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="cleaner" className="block text-sm font-medium text-gray-700">Cleaner</label>
            <input
              id="cleaner"
              name="cleaner"
              type="text"
              placeholder="Enter cleaner name"
              value={cleaner}
              onChange={(e) => setCleaner(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="region" className="block text-sm font-medium text-gray-700">Region</label>
            <select
              id="region"
              name="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
            >
              <option value="">Select region</option>
              {regions.map(region => (
                <option key={region} value={region}>{region}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Status (Select multiple)</label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {statuses.map(status => (
              <label key={status} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="status"
                  value={status}
                  checked={selectedStatuses.includes(status)}
                  onChange={() => handleStatusChange(status)}
                  className="form-checkbox h-5 w-5 text-lime-600 rounded focus:ring-lime-600"
                />
                <span className="text-sm text-gray-700 capitalize">{status}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-2">
            <label htmlFor="dateFrom" className="block text-sm font-medium text-gray-700">Date From</label>
            <div className="relative">
              <input
                id="dateFrom"
                name="dateFrom"
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="dateTo" className="block text-sm font-medium text-gray-700">Date To</label>
            <div className="relative">
              <input
                id="dateTo"
                name="dateTo"
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <button
            type="button"
            onClick={() => handleDatePreset('today')}
            className="px-4 py-2 border border-lime-600 text-lime-600 rounded-md hover:bg-lime-600 hover:text-white transition-colors"
          >
            Today
          </button>
          <button
            type="button"
            onClick={() => handleDatePreset('tomorrow')}
            className="px-4 py-2 border border-lime-600 text-lime-600 rounded-md hover:bg-lime-600 hover:text-white transition-colors"
          >
            Tomorrow
          </button>
          <button
            type="button"
            onClick={() => handleDatePreset('next7days')}
            className="px-4 py-2 border border-lime-600 text-lime-600 rounded-md hover:bg-lime-600 hover:text-white transition-colors"
          >
            Next 7 Days
          </button>
        </div>
        <div className="space-y-2">
          <label htmlFor="sort" className="block text-sm font-medium text-gray-700">Sort By</label>
          <select
            id="sort"
            name="sort"
            value={sort}
            onChange={(e) => setSort(e.target.value as "date" | "rdate")}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparent"
          >
            {sortOptions.map(option => (
              <option key={option.id} value={option.id}>{option.label}</option>
            ))}
          </select>
        </div>
        <button
          type="button"
          onClick={handleSearch}
          className="w-full px-4 py-2 bg-lime-600 text-white rounded-md hover:bg-lime-700 transition-colors"
        >
          Search
        </button>
      </div>
    </div>
  )
}